<script setup lang="ts">
  const bookingStore = useBookingStore();
  const pageIngoStore = usePageInfoStore();
  const { agreeCheckbox, errorText, responseCode } = storeToRefs(useBookingStore());
  const { checkAgreeCheckbox } = bookingStore;
  const type = computed(() => {
    return (responseCode.value === 500) ? 'server' : (responseCode.value === 2466) ? 'no-slots' : '';
  });
</script>

<template>
  <div class="checkbox-container">
    <!--  @change="toggle()"  :value="true"
      :unchecked-value="false"-->
    <input
      v-model="agreeCheckbox"
      :value="agreeCheckbox"
      class="checkbox"
      type="checkbox"
      name="agreeCheckbox"
      @change="checkAgreeCheckbox"
    >
    <div class="checkbox-text">
      Соглашаюсь с условиями
      <NuxtLink external target="_blank" class="checkbox-link" :to="pageIngoStore.agreements?.personal.url">
        обработки персональных данных
      </NuxtLink>,
      принимаю
      <NuxtLink external target="_blank" class="checkbox-link" :to="pageIngoStore.agreements?.agreement.url">
        Пользовательское соглашение на бронирование времени услуги
      </NuxtLink>
      и
      <NuxtLink external target="_blank" class="checkbox-link" :to="pageIngoStore.agreements?.yandex.url">
        политику обработки данных
      </NuxtLink>.
    </div>
    <div>
      <span v-if="errorText && responseCode !== 2466 && responseCode !== 500" style="margin-left: 0" class="input-error-message">{{ errorText }}</span>
    </div>
    <LazyBookingViewsBannerError v-if="errorText && responseCode === 2466 || errorText && responseCode === 500" :type="type" />
  </div>
</template>

<style lang="scss">
.checkbox-container {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}
.checkbox {
  width: 24px;
  height: 24px;
}
.checkbox-text {
  color: #262633;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: calc(100% - 40px);
}
.checkbox-link {
  color: #2D7FF9 !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.select-doctor-button {
  width: 100%;
  height: 56px;
  padding: 15px 16px;
  border-radius: 15px;
  background-color: #2D7FF9;
  color: white;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  text-align: center;
  cursor: pointer;
}
</style>
