<script setup lang="ts">
  import { onMounted } from 'vue';
  const props = defineProps({
    callback: {
      type: Function,
      required: true
    },
    visible: {
      type: Boolean,
      default: false
    }
  });

  const emit = defineEmits(['error']);

  const captchaContainer = ref(null);
  function onloadFunction() {
    if (captchaContainer.value && window.smartCaptcha) {
      const widgetId = window.smartCaptcha.render(captchaContainer.value, {
        sitekey: 'ysc1_3gkqWnyjXb3XEtI2511zJYRbtZDbpRKnIu7lhKgdb3e5f271',
        hl: 'ru',
        invisible: !props.visible,
        callback: props.callback
        // ,test: true // для теста
      });

      window.smartCaptcha.subscribe(
        widgetId,
        'challenge-hidden',
        () => () => {
          // todo make emit for loading
        }
      );
      // doesnt work with error
      window.smartCaptcha.subscribe(
        widgetId,
        'javascript-error',
        () => () => {
          emit('error', true);
          console.error('Captcha is not work');
        }
      );
      window.smartCaptcha.subscribe(
        widgetId,
        'network-error',
        () => () => {
          emit('error', true);
          console.error('Captcha is not work');
        }
      );
      // always 0, need another check
      if (widgetId === 0) {
        emit('error', true);
        console.error('Captcha is not work');
      }
    }
  }

  onMounted(() => {
    // подключаем скрипт капчи при монтировании
    const script = document.createElement('script');
    script.src = 'https://smartcaptcha.yandexcloud.net/captcha.js?render=onload&onload=onloadFunction';
    script.async = true;
    script.defer = true;

    // загружаем капчу после монтирования скрипта
    script.onload = () => {
      if (captchaContainer.value && window.smartCaptcha) {
        onloadFunction();
      } else {
        window.onloadFunction = onloadFunction;
      }
    };

    document.head.appendChild(script);
  });
</script>

<template>
  <div
    ref="captchaContainer"
    :class="{'visible': visible}"
  ></div>
</template>

<style>
  .visible {
    margin-bottom: 24px;
  }
</style>
